import React, { useEffect } from "react"
import "../theme/info.less"
import { navigate } from "gatsby"

const InfoPage = () => {
  useEffect(() => {
    navigate("/info/terms-of-use/")
  }, [])

  return <div></div>
}

export default InfoPage
